<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="700px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="editedItem.depot_id"
                    item-text="label"
                    :item-value="'id'"
                    :items="depots"
                    :rules="[(v) => !!v || 'Depot obligatoire']"
                    label="Depot"
                    :readonly="item.id >= 0"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select
                    ref="statut"
                    v-model="editedItem.statut_id"
                    item-text="label"
                    :item-value="'id'"
                    :items="statuts"
                    label="Statut"
                    readonly
                    chips
                    dense
                    class="mt-2"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.date_phase1"
                    label="Debut Comptage"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="editedItem.equipe1_id"
                    :items="equipes1"
                    :item-text="'label'"
                    :item-value="'id'"
                    :label="
                      nbcompte == 2 ? 'Equipe 1er Comptage' : 'Equipe Comptage'
                    "
                    :readonly="readonly || editedItem.statut_id > 1"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="nbcompte == '2'">
                  <v-select
                    v-model="editedItem.equipe2_id"
                    :items="equipes2"
                    :item-text="'label'"
                    :item-value="'id'"
                    label="Equipe 2eme Comptage"
                    :readonly="readonly || editedItem.statut_id > 1"
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="nbcompte == 2">
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.date_phase2"
                    label="Debut 3eme Comptage"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="nbcompte == '2'">
                  <v-select
                    v-model="editedItem.equipe3_id"
                    :items="equipes3"
                    :item-text="'label'"
                    :item-value="'id'"
                    label="Equipe 3eme Comptage"
                    :readonly="readonly || editedItem.statut_id > 2"
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.date_phase3"
                    label="Debut Explication"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5">
                  <v-autocomplete
                    v-model="editedItem.expl_id"
                    item-text="nom"
                    :item-value="'id'"
                    :items="agents_list"
                    label="Responsable"
                    :readonly="readonly"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn
              ref="save"
              color="blue darken-1"
              text
              @click="save"
              v-if="!readonly"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_INVENT_DEPOT from "../graphql/Inventaire/CREATE_INVENT_DEPOT.gql";
import UPDATE_INVENT_DEPOT from "../graphql/Inventaire/UPDATE_INVENT_DEPOT.gql";

export default {
  components: {},
  name: "depotdetailform",
  props: {
    item: Object,
    items: Array,
    showForm: Boolean,
    readonly: Boolean,
    inv_id: Number,
    nbcompte: Number,
    alldepots: Array,
    equipes: Array,
    agents_list: Array,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    statuts: [
      { id: "1", label: "Comptage" },
      { id: "2", label: "3eme Comptage" },
      { id: "3", label: "Explication" },
    ],
  }),

  computed: {
    depots() {
      let yFilter = this.items.map((itemY) => {
        return itemY.depot_id;
      });
      yFilter = yFilter.filter((elm) => elm != this.editedItem.depot_id);
      let filteredX = this.alldepots.filter(
        (itemX) => !yFilter.includes(itemX.id)
      );

      return filteredX;
    },
    equipes1() {
      let filteredX = this.equipes.filter(
        (itemX) =>
          itemX.id != this.editedItem.equipe2_id &&
          itemX.id != this.editedItem.equipe3_id
      );

      return filteredX;
    },
    equipes2() {
      let filteredX = this.equipes.filter(
        (itemX) =>
          itemX.id != this.editedItem.equipe1_id &&
          itemX.id != this.editedItem.equipe3_id
      );

      return filteredX;
    },
    equipes3() {
      let filteredX = this.equipes.filter(
        (itemX) =>
          itemX.id != this.editedItem.equipe2_id &&
          itemX.id != this.editedItem.equipe1_id
      );

      return filteredX;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Ajout Depot  "
          : "Depot : ".concat(this.item.depot_name);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      if (this.editedItem.id < 0) {
        this.editedItem.statut_id = "1";
        this.editedItem.date_phase1 = this.$store.state.today;
      }
    }
    if (this.nbcompte == 1) this.statuts.splice(1, 1);
  },

  methods: {
    close() {
      this.$emit("close", this.editedItem);
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let i = this.alldepots.findIndex(
          (elm) => elm.id == this.editedItem.depot_id
        );
        if (i >= 0) {
          this.editedItem.depot_name = this.alldepots[i].label;
          this.editedItem.statut_name =
            this.statuts[parseInt(this.editedItem.statut_id) - 1].label;
        }
        if (this.item.id > 0) {
          let v = {
            invdepot: {
              id: this.item.id,
              equipe1_id: this.editedItem.equipe1_id,
              equipe2_id: this.editedItem.equipe2_id,
              equipe3_id: this.editedItem.equipe3_id,
              expl_id: this.editedItem.expl_id,
              write_uid: this.$store.state.me.id,
              create_uid: this.$store.state.me.id,
            },
          };
          this.maj(UPDATE_INVENT_DEPOT, v);
          this.items.splice(this.editedItem.index, 1, this.editedItem);
          this.$emit("update");
        } else {
          let v = {
            invdepot: {
              inv_id: this.inv_id,
              depot_id: this.editedItem.depot_id,
              statut_id: this.editedItem.statut_id,
              date_phase1: this.editedItem.date_phase1,
              equipe1_id: this.editedItem.equipe1_id,
              equipe2_id: this.editedItem.equipe2_id,
              equipe3_id: this.editedItem.equipe3_id,
              expl_id: this.editedItem.expl_id,
              write_uid: this.$store.state.me.id,
              create_uid: this.$store.state.me.id,
            },
          };
          let r = await this.maj(CREATE_INVENT_DEPOT, v);
          if (r) this.editedItem.id = r.createInvDepot.id;
          this.items.push(this.editedItem);
          this.$emit("add");
        }
      }
    },
  },
};
</script>
